export const iframeUrls = [
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7246390413069094912',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7245062232508678144',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7241426305957732352',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7229477831372652544',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7227584804496494592',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7224330339668672513',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7218912575978115074',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7207097352065536001',
	'https://www.linkedin.com/embed/feed/update/urn:li:share:7201953041271472128',
	'https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7121859420229103616',
];
