import React from 'react';
import { Box, Container, Grid, Link, IconButton, Button, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { contactInfos } from '../misc/text/footer.js';
import sqlogodark from '../imgs/oxari_square_dark.svg';

const ContactInfoTitle = styled(Typography)({
	fontWeight: 'bold',
	textTransofrm: 'capitalize',
});

const ContactInfoCtx = styled(Typography)({ fontWeight: 'light' });

const ContactSectionTitle = ({ children, ...otherProps }) => (
	<Typography variant='h6' my={3} {...otherProps}>
		{children}
	</Typography>
);
const ContactInfoBox = ({ title, info }) => {
	return (
		<Box display={{ xs: 'grid', md: 'flex' }} mx={{ xs: 0, md: 1 }}>
			<ContactInfoTitle variant='h6' mr={1}>
				{title}
			</ContactInfoTitle>
			<ContactInfoCtx variant='h6'>{info}</ContactInfoCtx>
		</Box>
	);
};

export default function Footer() {
	const history = useHistory();

	const handleOnClick = (e) => {
		e.preventDefault();
		history.push(e.target.pathname);
	};

	const currentYear = new Date().getFullYear();
	const copyrightTxt = `Copyright ${currentYear} © Oxari`;

	return (
		<Box
			id='footer'
			bgcolor='black'
			color='background.plain'
			p={{ xs: 2 }}
			bottom={0}
			position={'static'}
			width={'100%'}
			marginTop={'auto'}
		>
			<Container>
				<Box
					display='flex'
					justifyContent={{ xs: 'unset', md: 'space-between' }}
					flexWrap={{ xs: 'wrap', md: 'nowrap' }}
					gap={{ xs: 2, sm: 4, md: 10 }}
					my={4}
				>
					<Box display='inline-flex' flex={'1 1 200px'} flexDirection='column'>
						<img src={sqlogodark} alt='Oxari Logo Dark' width='20%' />
						<ContactSectionTitle marginLeft={1}>Contact Information</ContactSectionTitle>

						{contactInfos.map((el, i) => (
							<ContactInfoBox key={`info${i}`} title={el.title} info={el.info} />
						))}
					</Box>
					<Box display={'inline-flex'} flexDirection={'column'}>
						<ContactSectionTitle variant='h6'>Social Media</ContactSectionTitle>
						<Box display='flex' gap={2}>
							<IconButton
								variant='socialMedia'
								target='_blank'
								href='https://www.linkedin.com/company/oxari-bv'
							>
								<LinkedInIcon fontSize='inherit' />
							</IconButton>

							<IconButton
								variant='socialMedia'
								target='_blank'
								href='https://www.youtube.com/channel/UCmenVr9JoXq6bo7BT9v0bmA'
							>
								<YouTubeIcon fontSize='inherit' />
							</IconButton>
						</Box>
					</Box>

					<Box display='inline-flex' flexDirection={'column'}>
						<ContactSectionTitle>Join our newsletter!</ContactSectionTitle>
						<Button
							onClick={() => window.open('http://eepurl.com/idMB5f', '_blank')}
							variant='containedOutlined'
							color='whiteToGreen'
							sx={{
								paddingX: '3rem',
								fontSize: '1.05rem',
								textTransform: 'capitalize',
							}}
						>
							Subscribe
						</Button>
					</Box>
					{/* </Grid> */}
				</Box>
				<Grid container py={{ xs: 2, md: 4 }} justifyContent='space-between'>
					<Grid item>
						<Link
							href='/privacy_statement'
							variant='whiteToGreenUnderline'
							mr={{ xs: 2, lg: 8 }}
							onClick={handleOnClick}
						>
							Privacy policy
						</Link>

						<Link href='/faq' variant='whiteToGreenUnderline' onClick={handleOnClick}>
							FAQ
						</Link>
					</Grid>
					<Grid item>
						<Typography textAlign='right' variant='h6'>
							{copyrightTxt}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}
