import ForestIcon from '@mui/icons-material/Forest';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

export const products = [
	{
		id: '0',
		title: 'Corporate Carbon Data Explorer',
		icon: ForestIcon,
		list: [
			"Estimate your own or other companies' carbon emissions from past data?",
			'Fill gaps in your Scope 1-3 emission data?',
			'Evaluate the carbon emissions produced by your current investment portfolio?',
			'Identify frontrunners and laggards in reducing harmful emissions?',
			'Analyze decoupling between financial growth and emissions?',
		],
		paragraphs: [
			"The Oxari Corporate Carbon Data Explorer provides access to reported and predicted emission data, as well as firmographic financial data, for over 50,000 companies. You can access this data via our API or an intuitive dashboard. This tool goes beyond just providing data; it is the first of its kind to offer precise emission estimates based on country, industry, and company-level inputs. As a result, it can estimate a company's emissions even without direct reporting or pre-existing data in our database.",
			'Using advanced artificial intelligence and machine learning techniques, we can estimate and predict emissions—past and future—for any company of your choice. You can also input your own data for predictions. Our finely tuned AI models account for various economic, financial, and political factors, continually minimizing prediction errors.',
		],
		extra: '',
	},
	{
		id: '1',
		title: 'Temperature Scoring',
		icon: DeviceThermostatIcon,
		list: [
			'Assess the climate compatibility of companies and build climate-neutral investment portfolios?',
			'Consider multiple policy and technology scenarios?',
			'Evaluate your own climate compatibility for a fast and reliable second opinion?',
			'Determine if you’ve successfully decoupled financial growth from emissions?',
		],
		paragraphs: [
			'The Oxari Temperature Score (OTS) estimates the global temperature increase by 2100 if a company continues its current performance. OTS aligns historical carbon emission growth (Scopes 1 and 2, and in some cases Scope 3) with well-established climate scenarios from scientific reports such as the IPCC.',
			'Since the socio-economic and political future is uncertain, OTS allows users to choose from nine scenarios based on different policy (Carbon Pricing) and technology (Carbon Removal) pathways.',
			'Combining established climate research, AI, and customizable climate & economic outlooks, we offer a cutting-edge solution to estimate your personalized temperature score. The Oxari Emissions Database contains data for over 50,000 companies worldwide, enabling us to provide Temperature Scores for all of them.',
		],
		extra:
			'Derived from the OTS, we also offer a Decoupling Score, which measures the relationship between a company’s revenue growth and its environmental footprint. Companies that increase revenue while reducing emissions demonstrate sustainable performance.',
	},
	{
		id: '2',
		title: 'Custom Climate, Economic & ESG Dashboards',
		icon: SpaceDashboardIcon,
		list: [
			'Track your sustainability performance in a comprehensive, data-driven dashboard?',
			'Present and visualize your sustainability insights for customers and clients?',
		],
		paragraphs: [
			'As sustainability issues gain more traction in business, policy, and consumer behavior, presenting ESG performance and data effectively has become crucial.',
			'Accessible, clear, and visually appealing maps, graphs, and interactive tools increase engagement and strengthen client relationships. Our experts can develop a customized dashboard tailored to your specific needs and use cases.',
			'Below is an example of a dashboard we have developed for our clients.',
		],
		extra: '',
	},
	{
		id: '3',
		title: 'From ESG Data to Knowledge and Beyond with Athena',
		icon: AutoAwesomeIcon,
		list: [
			'Access a comprehensive ESG database, built from thousands of data points sourced from reports, websites, and more?',
			'Query ESG-related information using natural language?',
			'Understand the ESG strategies of any company and how they compare to competitors?',
			'Create a visual and comprehensive analysis backed by real data within minutes?',
		],
		paragraphs: [
			'The ESG Knowledge Platform features three integrated solutions: Athena DB, Athena AI, and Athena Custom Integrations—designed to meet all ESG-related needs.',
			'Athena aims to be the largest and most affordable ESG data, knowledge, and analytics platform on the market. Currently in development, a working prototype will be available soon. To learn more about Athena’s capabilities, check out our video.',
		],
		extra: '',
	},
];
