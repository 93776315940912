import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { Box, Container, Grid, Typography, Button, Modal, Link } from '@mui/material';

import Figures from '../components/figures';
import ConfidenceRating from '../components/confidence_rating';
import TestimonialsCarousel from '../components/testimonials_card';
import Newsfeed from '../components/newsfeed';
import ContactForm from '../components/contact_form';
import VerticalTicker from '../components/vertical_ticker.js';
import { servicePitchPts, oxVisions } from '../misc/text/home.js';
import bgLGrey from '../imgs/bgLGrey.svg';
import bgDGrey from '../imgs/bgDGrey.svg';
// import dataradeBadge from '../imgs/datarade_100_dark_gradient_badge.svg';

import sdg8 from '../imgs/sdgs/E_WEB_INVERTED_08.png';
import sdg11 from '../imgs/sdgs/E_WEB_INVERTED_11.png';
import sdg13 from '../imgs/sdgs/E_WEB_INVERTED_13.png';
import sdg17 from '../imgs/sdgs/E_WEB_INVERTED_17.png';

import { LUCRUM_URL, BACKEND_URL } from '../components/constants';

function HomeSection({ children, title, titleColor, sectionBgColor = 'white', ...otherProps }) {
	return (
		<Box
			bgcolor={sectionBgColor}
			display='flex'
			alignItems='center'
			py={{ xs: 6, md: 12 }}
			px={{ xs: 4, md: 2, lg: 0 }}
		>
			<Container>
				{title && (
					<Typography variant='h4' color={titleColor} fontWeight={'bold'} mb={4}>
						{title}
					</Typography>
				)}
				<Grid container {...otherProps}>
					{children}
				</Grid>
			</Container>
		</Box>
	);
}

const HeroBG = styled(Box)({
	minHeight: '80vh',
	margin: 'auto',
	display: 'flex',
	alignItems: 'center',
	'&::before, &::after': {
		position: 'absolute',
		content: '""',
		top: 0,
		right: 0,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		width: '50vw',
		height: '90vh',
		'@media (max-width: 900px)': {
			opacity: 0.3,
		},
	},
	'&::before': {
		zIndex: -2,
		backgroundImage: `url(${bgLGrey})`,
	},
	'&::after': {
		zIndex: -1,
		backgroundImage: `url(${bgDGrey})`,
	},
});

const textShine = keyframes`
  0% {
    background-position: 50% 0%;
    }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
`;

const GlowingTxt = styled(Typography)({
	width: '100%',
	margin: 'auto',
	textAlign: 'center',
	marginBottom: '1rem',
	span: {
		background: 'linear-gradient(180deg,  #D7D7D7, #868683, #39393A 75%)',
		// textShadow: '0 0 1px transparent, 0 0 1em rgba(#2CEA56,0.5)',
		webkitBackgroundClip: 'text',
		backgroundClip: 'text',
		webkitTextFillColor: 'transparent',
		textFillColor: 'transparent',
		backgroundSize: '200% 200%',
		animation: `${textShine} 5s ease infinite`,
	},
});

function HeroSection() {
	const theme = useTheme();

	const [initCredits, setInitCredits] = useState(100);

	useEffect(() => {
		getInitCredits();
	}, []);

	async function getInitCredits() {
		try {
			const response = await fetch(`${BACKEND_URL}/user/initial-credits`);
			const { payload } = await response.json();
			setInitCredits(payload.initial_user_credits[0]);
		} catch (err) {
			console.error(err);
		}
	}

	return (
		<HeroBG>
			<Container>
				<Box
					display={'inline-flex'}
					width='100%'
					flexDirection={{ xs: 'column', md: 'row' }}
					justifyContent={{ xs: 'center', lg: 'space-between' }}
					alignItems={'center'}
					minHeight={'80vh'}
					gap={4}
				>
					<Box>
						<Typography
							variant='h4'
							fontWeight={'500'}
							color={theme.palette.primary.bright_green}
							mb={2}
						>
							Empowering Sustainable Progress
						</Typography>
						<Typography variant={'h1'} fontWeight={'700'} color='black' mb={2}>
							Trusted
							<br />
							ESG
							<br />
							Data
						</Typography>
						<VerticalTicker
							items={['Accurate.', 'Automatable.', 'Affordable.', 'Actionable.'].sort(
								() => Math.random() - 0.5
							)}
							variant='h2'
							fontWeight='500'
						/>
					</Box>
					<Box
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
						justifyContent={'center'}
					>
						<Button
							component={Link}
							href={LUCRUM_URL + '/signup'}
							rel='noopener noreferrer'
							sx={{
								fontSize: theme.typography.h6,
								textTransform: 'Capitalize',
								padding: 2,
								mb: 1,
								borderRadius: 2,
								background: theme.palette.primary.button_and_highlights,
								color: theme.palette.secondary.dark,
								'&:hover': {
									background: theme.palette.primary.main,
									color: theme.palette.secondary.dark,
								},
							}}
						>
							Sign up for Free
						</Button>
						<Box>
							<Typography
								variant='subtitle2'
								sx={{
									[theme.breakpoints.down('xs')]: {
										color: theme.palette.secondary.dark,
									},
									[theme.breakpoints.up('sm')]: {
										color: theme.palette.secondary.light,
									},
								}}
							>
								Start exploring with {initCredits} free credits.
							</Typography>
						</Box>
					</Box>
				</Box>
				<GlowingTxt variant='h3'>
					<span>🡫</span>
				</GlowingTxt>
			</Container>
		</HeroBG>
	);
}

function HomePitchBox({ ctx }) {
	const theme = useTheme();
	const imgW = ctx.width ?? '50px';
	const imgH = ctx.height ?? '50px';

	return (
		<Grid item md={4} display={'flex'} alignItems={'center'} my={{ xs: 2, md: 0 }}>
			<Box
				bgcolor={theme.palette.primary.button_and_highlights}
				width={imgW}
				height={imgH}
				borderRadius={0.5}
			>
				<img width={imgW} height={imgH} src={ctx.icon} alt={ctx.description} />
			</Box>
			<Typography
				variant='h6'
				color={theme.palette.secondary.light}
				lineHeight={{ xs: 1.2, md: 1.4 }}
				fontWeight={'500'}
				mx={1}
			>
				{ctx.description}
			</Typography>
		</Grid>
	);
}

function Home() {
	const [isModal, setIsModal] = React.useState(false);
	const handleClickEvent = () => {
		setIsModal((prev) => !prev);
	};

	const theme = useTheme();

	return (
		<Box sx={{ flexGrow: 1, paddingTop: '0px', mt: '0px', pt: '0px' }}>
			<Helmet key='home'>
				{/* <meta */}
				{/* 	charSet='utf-8' */}
				{/* 	name='description' */}
				{/* 	content='Green Fintech company offering fast and reliable emissions machine learning estimates for over ten thousands of companies and growing, backed by carefully-crafted machine learning algorithms. We seek to provide important information for investors, at low cost and high quality, considering climate change-related shifts in the financial markets.' */}
				{/* /> */}
				<title>Oxari - Home</title>
				<link rel='canonical' href='https://oxari.io/en' />
			</Helmet>
			{/* Title section */}
			<HeroSection />

			{/* Pitch section */}

			<HomeSection
				sectionBgColor={theme.palette.secondary.dark}
				title='Make informed decisions based on accessible sustainablility meterics'
				titleColor='white'
			>
				{servicePitchPts.map((el, i) => (
					<HomePitchBox key={`homePitch${i}`} ctx={el} />
				))}
			</HomeSection>

			{/* Vision section */}
			<HomeSection title='About' titleColor={theme.palette.primary.bright_green}>
				{oxVisions.map((el, i) => (
					<Grid key={`homeVision${i}`} item md={6} lg={6}>
						<Typography variant='h5' fontWeight={'bold'} mb={3}>
							{el.title}
						</Typography>
						<Typography variant='h6'>{el.text}</Typography>
					</Grid>
				))}
			</HomeSection>

			{/* Marketing Figures */}
			<HomeSection rowSpacing={6}>
				<Figures />
			</HomeSection>

			{/* Model Confidence */}
			<HomeSection spacing={6} rowSpacing={12}>
				<ConfidenceRating />
			</HomeSection>

			{/* Badges */}

			{/* <HomeSection>
				<Box
					component={Link}
					mx='auto'
					display={'flex'}
					alignItems={'center'}
					flex-wrap={'wrap'}
					href='https://datarade.ai/data-providers/oxari/profile'
					color='inherit'
					underline='hover'
				>
					<img
						src={dataradeBadge}
						alt='Datarade Badge'
						aria-describedby='dataradeBadge-description'
						style={{ margin: 'auto' }}
					/>
					<Typography id='dataradeBadge-description' my={2} variant={'h4'}>
						Certified by Datarade for quality and reliability.
					</Typography>
				</Box>
			</HomeSection> */}

			{/* News */}
			<Box>
				<Newsfeed />
			</Box>

			{/* word-of-mouth */}

			<HomeSection
				display='flex'
				justifyContent='center'
				alignItems='center'
				sectionBgColor='white'
				title='Building trust with our partners'
			>
				<Grid item>
					<TestimonialsCarousel />
				</Grid>
			</HomeSection>

			{/* Contact */}

			<HomeSection
				alignItems='center'
				sectionBgColor={theme.palette.primary.light}
				title='Every investment has an impact'
				titleColor={theme.palette.secondary.dark}
			>
				<Grid item md={6}>
					<Typography variant='body1'>
						We want to help both small and big investors to evaluate companies and use their
						influence to create the sustainable businesses of tomorrow.
					</Typography>
					<Typography variant='body1' my={3}>
						Oxari contributes to the following sustainable development goals (SDGs):
					</Typography>
					{/* SDG Images Row */}
					<Grid container spacing={2}>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg8} alt='SDG 8' width='100%' />
						</Grid>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg11} alt='SDG 11' width='100%' />
						</Grid>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg13} alt='SDG 13' width='100%' />
						</Grid>
						<Grid item xs={6} sm={2} md={3}>
							<img src={sdg17} alt='SDG 17' width='100%' />
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={6} width={'100%'}>
					<Box
						display='flex'
						mx='auto'
						justifyContent='center'
						flexDirection='column'
						alignItems='center'
					>
						<Typography variant='body1' my={3} textAlign={{ md: 'center' }}>
							Do you want to use our data or find out more?
						</Typography>
						<Button
							variant='contained'
							sx={{
								background: '#39393A',
								color: '#ffffff',
								textTransform: 'none',
								'&:hover': {
									backgroundColor: '#fff',
									borderColor: '#39393A',
									color: '#39393A',
								},
							}}
							onClick={handleClickEvent}
						>
							Contact us
						</Button>
					</Box>
				</Grid>
			</HomeSection>

			<Modal open={isModal} onClose={handleClickEvent}>
				<>
					<ContactForm />
				</>
			</Modal>
		</Box>
	);
}

export const HomePage = withRouter(Home);
